import { AppSettingsModel } from '../models/admin/app-settings.model';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppSettingsService {
  private httpClient: HttpClient;
  private appSettings: AppSettingsModel;

  constructor(handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  getProviderIdentity() {
    return this.appSettings.PROVIDER_IDENTITY;
  }

  getAdminApi() {
    return this.appSettings.ADMIN_API
  }

  getPrivateOnboardingApi() {
    return `${this.appSettings.ONBOARDING_API}/api`;
  }

  getPublicOnboardingApi() {
    return this.appSettings.ONBOARDING_API;
  }

  getHiperstream3() {
    return this.appSettings.HIPER_STREAM_3;
  }

  getHelpUrl() {
    return this.appSettings.HELP_URL;
  }

  async loadSettings(): Promise<any> {
    return this.httpClient
      .get<AppSettingsModel>('appSettings.json')
      .toPromise()
      .then((appSettings) => {
        this.appSettings = appSettings;
      });
  }
}
