import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(private translateService: TranslateService) { }
  expiredSessionKey = 'Commons.ExpiredSession';
  expiredSessionError = 'You provided \'undefined\' where a stream was expected. You can provide an Observable, Promise, Array, or Iterable.';

  getClientErrorMessage(error: Error): string {
    return error.message == this.expiredSessionError ?  this.translateService.instant(this.expiredSessionKey) : error.message? error.message : error.toString();
  }

  getServerErrorMessage(error: HttpErrorResponse): string {
    return navigator.onLine ? error.message : 'No Internet Connection';
  }

  getBadRequestMessage(errorObject: { Key: string; Message: string; }): string {
    const messageTranslate = this.translateService.instant(errorObject.Key);
    return messageTranslate === errorObject.Key ? errorObject.Message : messageTranslate;
  }
}
