import { AppSettingsService } from './app-settings.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpRequestService } from './http-request.service';
import { Injectable } from '@angular/core';
import { LanguageType } from '../models/admin/language-type.model';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  defaultLanguage = new BehaviorSubject<string>('');
  private onReload = new Subject();
  onReload$ = this.onReload.asObservable();

  constructor(private httpRequestService: HttpRequestService, private appSettingsService: AppSettingsService) {}

  isPtBr() {
    return this.defaultLanguage.getValue() === 'pt';
  }

  getDateFormat(): string {
    return this.isPtBr() ? 'dd/MM/yyyy HH:mm:ss' : 'yyyy-MM-dd HH:mm:ss';
  }

  reload(): void {
    this.onReload.next();
  }

  getLanguages() {
    return this.httpRequestService.get<LanguageType[]>(this.appSettingsService.getAdminApi(), '/api/Language/GetLanguages');
  }

  saveLanguageDefault(userId: number, languageId: number) {
    return this.httpRequestService.post<any>(
      this.appSettingsService.getAdminApi(),
      `/Language/SaveLanguageDefault?userId=${userId}&languageId=${languageId}`,
      null,
    );
  }

  getLocale(): string {
    return this.isPtBr() ? 'pt-BR' : 'en-US';
  }
}
