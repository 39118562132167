import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpRequestService {
  constructor(private httpClient: HttpClient, private jwtHelper: JwtHelperService) {}

  post<T>(api: string, url: string, data: any, addToken = true): Observable<T> {
    return this.httpClient
      .post<T>(this.joinEndPoint(api, url), data, { withCredentials: addToken })
      .pipe(map(this.extractData), catchError(this.handleError.bind(this)));
  }

  postWithCompanyToken<T>(api: string, url: string, data: any, token: string): Observable<T> {
    const headers = new HttpHeaders()
      .append('Accept', 'application/json')
      .append('Content-Type', 'application/json')
      .append('Authorization', `Bearer ${token}`);
    return this.httpClient
      .post<T>(this.joinEndPoint(api, url), data, { headers: headers })
      .pipe(map(this.extractData), catchError(this.handleError.bind(this)));
  }

  postLogin<T>(api: string, url: string, data: any, addToken = true): Observable<T> {
    return this.httpClient
      .post<T>(this.joinEndPoint(api, url), data, this.getOptions(data, addToken))
      .pipe(map(this.extractData), catchError(this.handleError.bind(this)));
  }

  get<T>(api: string, url: string): Observable<T> {
    return this.httpClient
      .get<T>(this.joinEndPoint(api, url), { withCredentials: true })
      .pipe(map(this.extractData), catchError(this.handleError.bind(this)));
  }

  getQueryString<T>(api: string, url: string, params: HttpParams): Observable<T> {
    return this.httpClient
      .get(this.joinEndPoint(api, url), { params: params, withCredentials: true })
      .pipe(map(this.extractData), catchError(this.handleError.bind(this)));
  }

  getQueryStringBlob<T>(api: string, url: string, params: HttpParams): Observable<T> {
    return this.httpClient
      .get(this.joinEndPoint(api, url), {
        params: params,
        withCredentials: true,
        responseType: 'blob',
      })
      .pipe(map(this.extractData), catchError(this.handleError.bind(this)));
  }

  getOptions(params: HttpParams, addToken: boolean): {} {
    const headers = new HttpHeaders().append('Accept', 'application/json').append('Content-Type', 'application/json');
    if (addToken) {
      headers.append('Authorization', 'Bearer ' + this.jwtHelper.tokenGetter());
    }
    return [{ headers: headers, params: params }];
  }

  put<T>(api: string, url: string, data: any = null): Observable<T> {
    return this.httpClient
      .put<T>(this.joinEndPoint(api, url), data, { withCredentials: true })
      .pipe(map(this.extractData), catchError(this.handleError.bind(this)));
  }

  delete<T>(api: string, url: string): Observable<T> {
    return this.httpClient
      .delete<T>(this.joinEndPoint(api, url), { withCredentials: true })
      .pipe(map(this.extractData), catchError(this.handleError.bind(this)));
  }

  private joinEndPoint(api: string, url: string): string {
    return Location.joinWithSlash(api, url);
  }

  private extractData(res: any): any {
    const body = res;
    return body || {};
  }

  private handleError(error: any) {
    if (error.status === 401) {
      throw error;
    } else {
      throw error;
    }
  }
}
