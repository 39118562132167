<div class="toolbar-company" (onboardingClickOutside)="onCompanyClickOutside()" fxFlex="grow">
  <button class="button" (click)="toggleCompanyDropdown()" [class.open]="isCompanyOpen" mat-button fxFlex="grow">
    <span fxLayout="row" fxLayoutAlign="start center">
      <mat-icon class="icon" fxHide fxShow.gt-sm>business</mat-icon>
      <span class="name" fxShow.gt-sm>{{ companyName }}</span>
    </span>
  </button>

  <div class="dropdown" [class.open]="isCompanyOpen">
    <div class="content">
      <div class="list">
        <div *ngIf="companies && companies.length > 1">
          <div class="list-item" *ngFor="let company of companies" matRipple>
            <mat-icon class="list-item-icon">business</mat-icon>
            <span (click)="changeCompany(company)">{{ company.companyName }}</span>
          </div>
          <mat-divider></mat-divider>
        </div>
        <div class="list-item" [routerLink]="['/settings/token']" matRipple>
          <mat-icon class="list-item-icon">vpn_key</mat-icon>
          <span>{{ 'Toolbar.Token' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="toolbar-user" (onboardingClickOutside)="onUserClickOutside()" fxFlex="grow">
  <button class="button" mat-button (click)="toggleUserDropdown()" [class.open]="isUserOpen" fxFlex="grow">
    <span fxLayout="row" fxLayoutAlign="start center">
      <img class="avatar" [src]="imagePath" />
      <span class="name" fxHide fxShow.gt-sm>{{ userName }}</span>
      <mat-icon class="icon" fxHide fxShow.gt-sm>keyboard_arrow_down</mat-icon>
    </span>
  </button>

  <div class="dropdown" [class.open]="isUserOpen">
    <div class="content">
      <div class="list">
        <div class="list-item" [routerLink]="['/settings/profile']" matRipple>
          <mat-icon class="list-item-icon">account_circle</mat-icon>
          <span>{{ 'Toolbar.Profile' | translate }}</span>
        </div>
        <div class="list-item" [routerLink]="['/settings/security']" matRipple>
          <mat-icon class="list-item-icon">security</mat-icon>
          <span>{{ 'Toolbar.Security' | translate }}</span>
        </div>
        <mat-divider></mat-divider>
        <div class="list-item" matRipple (click)="signout()">
          <mat-icon class="list-item-icon">exit_to_app</mat-icon>
          <span>{{ 'Toolbar.Logout' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
