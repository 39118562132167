import { AppSettingsService } from 'src/@onboarding/services/app-settings.service';
import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { map } from 'rxjs/operators';
import { ThemeService } from 'src/@onboarding/services/theme.service';

@Component({
  selector: 'onboarding-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
  @Input()
  @HostBinding('class.no-box-shadow')
  hasNavigation: boolean;
  theme: any;
  @Output() openSidenav = new EventEmitter();

  topNavigation$ = this.themeService.config$.pipe(map((config) => config.navigation === 'top'));

  constructor(private themeService: ThemeService, private appSettingsService: AppSettingsService) {
    this.themeService.activeTheme$.subscribe((theme) => {
      this.theme = theme;
    });
  }

  ngOnInit() {}

  openHelp() {
    window.open(this.appSettingsService.getHelpUrl(), '_blank');
  }

  setTheme(theme: any) {
    this.themeService.setTheme(theme);
    this.theme = theme;
  }
}
