import { Injectable } from '@angular/core';
import { JwtModel } from '../models/admin/jwt.model';

@Injectable({
  providedIn: 'root',
})
export class JwtService {
  private LOCAL_STORAGE_TOKEN_KEY = 'jwt';

  save(token: JwtModel): void {
    localStorage[this.LOCAL_STORAGE_TOKEN_KEY] = JSON.stringify(token);
  }

  delete(): void {
    localStorage.removeItem(this.LOCAL_STORAGE_TOKEN_KEY);
  }

  get(): JwtModel {
    const jwt = localStorage[this.LOCAL_STORAGE_TOKEN_KEY];
    if (!jwt) {
      return null;
    }
    return JSON.parse(jwt);
  }
}
