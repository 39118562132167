import { ChangeDetectionStrategy, Component, Directive, Input, ViewEncapsulation } from '@angular/core';

// noinspection TsLint
@Component({
  selector: 'onboarding-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  host: { class: 'onboarding-card' },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Card {}

// noinspection TsLint
@Component({
  selector: 'onboarding-card-header',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'onboarding-card-header' },
  template: `
    <div class="onboarding-card-header-heading-group">
      <ng-content select="onboarding-card-header-heading"></ng-content>
      <ng-content select="onboarding-card-header-subheading"></ng-content>
    </div>
    <ng-content></ng-content>
    <ng-content select="onboarding-card-header-actions"></ng-content>
  `,
})
export class CardHeader {}

// noinspection TsLint
@Component({
  selector: 'onboarding-card-content',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'onboarding-card-content' },
  template: ` <ng-content></ng-content>`,
})
export class CardContent {}

// noinspection TsLint
@Directive({
  selector: 'onboarding-card-header-heading',
  host: { class: 'onboarding-card-header-heading' },
})
export class CardHeaderTitle {}

// noinspection TsLint
@Directive({
  selector: 'onboarding-card-header-subheading',
  host: { class: 'onboarding-card-header-subheading' },
})
export class CardHeaderSubTitle {}

// noinspection TsLint
@Directive({
  selector: 'onboarding-card-header-actions',
  host: { class: 'onboarding-card-header-actions' },
})
export class CardHeaderActions {}

// noinspection TsLint
@Directive({
  selector: 'onboarding-card-actions',
  host: {
    class: 'onboarding-card-actions',
    '[class.onboarding-card-actions-align-end]': 'align === "end"',
  },
})
export class CardActions {
  /** Position of the actions inside the card. */
  @Input() align: 'start' | 'end' = 'start';
}
