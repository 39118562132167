import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardHeaderActions,
  CardHeaderSubTitle,
  CardHeaderTitle,
} from './card.component';

const cardComponents = [
  Card,
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubTitle,
  CardHeaderActions,
  CardContent,
  CardActions,
];

@NgModule({
  imports: [CommonModule],
  declarations: [...cardComponents],
  exports: [...cardComponents],
})
export class CardModule {}
