<div class="toolbar" fxLayout="row" fxLayoutAlign="space-between">
  <div fxLayout="row" fxLayoutAlign="start center">
    <onboarding-toolbar-sidenav-mobile-toggle
      (openSidenav)="openSidenav.emit()"
    ></onboarding-toolbar-sidenav-mobile-toggle>

    <div [fxShow.gt-sm]="topNavigation$ | async" [fxShow.xs]="false" [routerLink]="['/']" class="toolbar-logo" fxShow>
      <img *ngIf="theme === 'onboarding-default'" src="assets/img/logo/hipervalidation.svg" alt=":hiperValidation" />
      <img *ngIf="theme === 'onboarding-dark'" src="assets/img/logo/hipervalidation-dark.svg" alt=":hiperValidation" />
    </div>
  </div>

  <div fxLayout="row">
    <button (click)="openHelp()" class="button toolbar-button" mat-button>
      <mat-icon>help</mat-icon>
    </button>

    <button
      *ngIf="theme === 'onboarding-default'"
      (click)="setTheme('onboarding-dark')"
      class="button toolbar-button"
      mat-button
    >
      <mat-icon>dark_mode</mat-icon>
    </button>

    <button
      *ngIf="theme === 'onboarding-dark'"
      (click)="setTheme('onboarding-default')"
      class="button toolbar-button"
      mat-button
    >
      <mat-icon>light_mode</mat-icon>
    </button>

    <onboarding-toolbar-fullscreen-toggle class="toolbar-button"></onboarding-toolbar-fullscreen-toggle>

    <onboarding-toolbar-user class="toolbar-button"></onboarding-toolbar-user>    
  </div>
</div>
