import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from '../services/error.service';
import { LoggingService } from '../services/logging.service';
import { NotificationService } from '../services/notification.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: Error | HttpErrorResponse) {
    const errorService = this.injector.get(ErrorService);
    const logger = this.injector.get(LoggingService);
    const notifier = this.injector.get(NotificationService);

    let message: string;
    if (error instanceof HttpErrorResponse) {
      if (this.isBadRequest(error)) {
        error.error.forEach((errorObject: { Key: string; Message: string }) => {
          message = errorService.getBadRequestMessage(errorObject);
          this.notifyError(notifier, message, logger, error);
        });
      } else {
        message = errorService.getServerErrorMessage(error);
        this.notifyError(notifier, message, logger, error);
      }
    } else {
      message = errorService.getClientErrorMessage(error);
      this.notifyError(notifier, message, logger, error);
    }
  }

  private notifyError(
    notifier: NotificationService,
    message: string,
    logger: LoggingService,
    error: Error | HttpErrorResponse,
  ) {
    notifier.showError(message);
    this.logger(logger, message, error);
  }

  private isBadRequest(error: HttpErrorResponse) {
    return error.status === 400 && error.error != null && error.error.length > 0;
  }

  private logger(logger: LoggingService, message: string, error: Error | HttpErrorResponse) {
    logger.logError(message);
    console.error(error);
  }
}
