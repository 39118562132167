import defaultsDeep from 'lodash-es/defaultsDeep';
import SimpleBar from 'simplebar';
import { AfterContentInit, Directive, ElementRef, Input, NgZone } from '@angular/core';
import { scrollbarOptions } from './scrollbar-options';

@Directive({
  selector: '[onboardingScrollbar],onboarding-scrollbar',
})
export class ScrollbarDirective implements AfterContentInit {
  @Input('onboardingScrollbar') options: Partial<any>;

  scrollbarRef: SimpleBar;

  constructor(private _element: ElementRef, private zone: NgZone) {}

  ngAfterContentInit() {
    const options = defaultsDeep(this.options, scrollbarOptions);

    this.scrollbarRef = new SimpleBar(this._element.nativeElement, options);
  }
}
