import { BackdropModule } from 'src/@onboarding/shared/backdrop/backdrop.module';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { LoadingIndicatorModule } from 'src/@onboarding/shared/loading-indicator/loading-indicator.module';
import { MaterialModule } from 'src/@onboarding/shared/material-components.module';
import { NavigationModule } from './navigation/navigation.module';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/@onboarding/shared.module';
import { SidenavModule } from './sidenav/sidenav.module';
import { ToolbarModule } from './toolbar/toolbar.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    LoadingIndicatorModule,
    SharedModule,
    // Core
    ToolbarModule,
    SidenavModule,
    BackdropModule,
    NavigationModule,
  ],
  declarations: [LayoutComponent],
})
export class LayoutModule {}
